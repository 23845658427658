import {Observable} from 'rxjs';
import {Searchable} from '../../../../shared/models/searchable';
import {User} from '../../../../shared/models/user/user';
import {Retrievable} from '../../../../shared/models/retrievable';
import {UserSearchCriteria} from '../../../../shared/models/user/user-search-criteria';
import {Saveable} from '../../../../shared/models/saveable';
import {Deleteable} from '../../../../shared/models/deleteable';
import {RestClientService} from '../../api-access/rest-client.service';
import {ChangePasswordRequest} from '../../../../profile/models/change-password-request';
import {Project} from '../../../../models/projects/project';
import {UserData} from '../../../../profile/models/user-data';
import {Injectable} from '@angular/core';

@Injectable()
export class UserRemoteService implements
        Searchable<User, UserSearchCriteria>,
        Retrievable<User, number>,
        Saveable<User>,
        Deleteable<User, number> {
  private URL = '/users';

  constructor(private restClientService: RestClientService) {}

  public search(criteria?: UserSearchCriteria): Observable<User[]> {
      return this.restClientService.get(this.URL, criteria);
  }

  public get(id: number): Observable<User> {
      return this.restClientService.get(`${this.URL}/profile/${id}`);
  }

  public getDepartmentUsers(id: number): Observable<User[]> {
      return this.restClientService.get(`${this.URL}/${id}/department-users`);
  }

  public save(user: User): Observable<any> {
      if (user.id !== undefined && user.id !== null) {
          return this.restClientService.put(`${this.URL}/${user.id}`, user);
      }
      return this.restClientService.post('register', user);
  }

  public delete(id: number): Observable<User> {
      return this.restClientService.delete(`${this.URL}/${id}`);
  }

  public resetPassword(id: number, password: string): Observable<User> {
      return this.restClientService.put(`${this.URL}/${id}/password`, {password, id});
  }

  public updatePassword(currentPassword: string, newPassword: string): Observable<User> {
      return this.restClientService.put(`${this.URL}/password`, {currentPassword, newPassword});
  }

  public forgotPassword(login: string, confirmationLink: string) {
    return this.restClientService.put(`${this.URL}/forgot-password/${login}`, {confirmationLink});
  }

  public uploadAvatar(userId: number, avatar: string): Observable<string> {
    return this.restClientService.post(`${this.URL}/${userId}/avatar`, {avatar});
  }

  public getAvatarUrl(userId: number): Observable<string> {
    return this.restClientService.get(`${this.URL}/${userId}/avatar`);
  }

  public changePassword(user: User, changePasswordRequest: ChangePasswordRequest) {
    return this.restClientService.put(`${this.URL}/${user.id}/change-password`, changePasswordRequest);
  }

  public getProjects(countProjects?: number): Observable<Project[]> {
    const request = {
      per_page: countProjects
    };
    return this.restClientService.get(`${this.URL}/projects`, request);
  }

  public userData(): Observable<UserData> {
    return this.restClientService.get(`${this.URL}/user-data`, null);
  }

  public getKms(): Observable<any> {
    return this.restClientService.get(`${this.URL}/kms`, null);
  }
}
