import { Injectable } from '@angular/core';
import { Retrievable } from '../../shared/models/retrievable';
import { Observable } from 'rxjs';
import {RestClientService} from '../../core/services/api-access/rest-client.service';
import {Configuration} from '../../models/common/configuration';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationRemoteService implements Retrievable<Configuration, string> {

  private URL = '/configurations';

  constructor(private restClientService: RestClientService) { }

  public get(key: string): Observable<Configuration> {
    return this.restClientService.get(`${this.URL}/${key}`);
  }
}
