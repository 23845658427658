import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {ProjectThumbnail} from 'src/app/models/project-thumbnail';

@Component({
  selector: 'app-gallery-slider',
  templateUrl: './gallery-slider.component.html',
  styleUrls: ['./gallery-slider.component.scss']
})
export class GallerySliderComponent {

  isClickItem = false;

  @Input() widthPercent = 30;
  @Input() height = 278;
  @Input() marginRight = 30;
  @Input() images: ProjectThumbnail[] = [];

  @Output() filterChange = new EventEmitter<string>();

  constructor(private router: Router) { }

  onSliderClick(projectId: number) {
    if (this.isClickItem) {
      this.router.navigateByUrl(`projects/${projectId}`);
    }
  }

  clickItem(isClick: boolean) {
    this.isClickItem = isClick;
  }
}
