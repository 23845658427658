import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RestClientErrorHandlerService {

    constructor() {}

    public handleError(httpErrorResponse: HttpErrorResponse) {
      return throwError(httpErrorResponse.error);
    }

    public handleBlobError(httpErrorResponse: HttpErrorResponse) {
        return this.parseErrorBlob(httpErrorResponse).pipe(catchError((err) => this.handleError(err)));
    }

    private parseErrorBlob(err: HttpErrorResponse): Observable<any> {
        const reader: FileReader = new FileReader();
        const obs = new Observable((observer: any) => {
            reader.onloadend = () => {
              const errResponse = Object.assign([], err);
              observer.error(errResponse);
              observer.complete();
            };
        });
        reader.readAsText(err.error);
        return obs;
    }
}
