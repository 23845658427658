<div class="road-downloader-dialog-container">
  <mat-dialog-content class="dialog-text">
    <div class="header-container">
      <div *ngIf="!formData" class="tittleheader">{{ 'excessKm' | i18n }} ({{'calculating' | i18n}})
        <span>{{ 'excessKmsub' | i18n }}</span>
      </div>
      <div *ngIf="formData" class="tittleheader">{{ 'excessKm' | i18n }} ({{exceededKms}} KM)
        <span>{{ 'excessKmsub' | i18n }}</span>
      </div>
      <!--<button class="cancel" mat-button [mat-dialog-close]="true" (click)="onNoClick()">X</button>-->
    </div>
    <div class="text-dialog-container">
      <div class="selection-notice">{{ 'whatdouwtd' | i18n }}</div>
    </div>
    <div class="km-pay">
      <div class="km-orange">
        <p class="title">{{'additionalCost' | i18n }}</p>
        <div class="horizontal-line"></div>
        <div class="euros">
          <p *ngIf="kmRate != null"> {{kmRate.price}}</p>
          <span>€/km</span>
        </div>
        <div class="horizontal-line"></div>
        <div class="taxes">
          <p>*{{'taxesIncluded' | i18n }}</p>
        </div>
        <form *ngIf="formData" id="formRedsys" action="{{formData.url}}" method="POST" #form>
          <input name="DS_SIGNATUREVERSION" value="{{formData.version}}" type="hidden">
          <input name="DS_MERCHANTPARAMETERS" value="{{formData.parameters}}" type="hidden">
          <input name="DS_SIGNATURE" value="{{formData.signature}}" type="hidden">
        </form>
        <div class="continue-button" [ngClass]="{ 'loading-btn': !formData }">
          <a *ngIf="!formData">{{'loading' | i18n}}</a>
          <a *ngIf="formData" (click)="goToPayRedsys()">{{'continue' | i18n}}</a>
        </div>

      </div>
      <div class="km-blue" *ngIf="nextRate != null">
        <p class="title"> {{'upgradeSub' | i18n }}</p>
        <div class="horizontal-line"></div>
        <p>{{'ksub' | i18n }} {{nextRate.kms}} KM</p>
        <div class="eurosv2">
          <p> {{nextRate.price}}</p>
          <span>€/mes</span>
        </div>

        <div class="horizontal-line"></div>
        <div class="taxes">
          <p>*{{'taxesIncluded' | i18n }}</p>
        </div>
        <div class="continue-button">
          <a href="/profile/payment-steps/{{nextRate.id}}/{{projectId}}">{{'continue' | i18n}}</a>
        </div>
      </div>
      <div class="km-green">
        <p class="title"> {{'equalKm' | i18n }}</p>
        <div class="horizontal-line"></div>
        <p>{{'equalKm2' | i18n }}</p>
        <div class="horizontal-line"></div>

        <div class="continue-button" [ngClass]="{ 'loading-btn': !userRateId }">
          <a *ngIf="!userRateId">{{'loading' | i18n}}</a>
          <a *ngIf="userRateId" href="/profile/payment-steps/{{userRateId}}/{{projectId}}">{{'continue' | i18n}}</a>
        </div>

      </div>
      <div class="km-purple">
        <p class="title"> {{'deleteKm' | i18n }}</p>
        <div class="horizontal-line"></div>
        <p>{{'deleteKm2' | i18n }}</p>
        <div class="horizontal-line"></div>

        <div class="continue-button">
          <a (click)="discardVideos()">{{'continue' | i18n}}</a>
        </div>

      </div>
    </div>

  </mat-dialog-content>
</div>