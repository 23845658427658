import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {UserToken} from '../../models/security/user-token';
import {BaseStorageService} from '../storage/base-storage.service';
import {StorageService} from '../storage/storage.service';
import {DateService} from '../api-services/date.service';

@Injectable({
  providedIn: 'root',
})
export class SecurityTokenStorage<T extends UserToken> extends BaseStorageService<T> {
  private OnSessionExpired = new Subject<UserToken>();

  constructor(storageService: StorageService) {
    super(storageService);
  }

  private static loginExpired(userToken: UserToken) {
    return DateService.datetimeIsBefore(DateService.getDate(userToken.tokenExpirationDate), DateService.currentDate());
  }

  public onSessionExpired(): Observable<any> {
    return this.OnSessionExpired;
  }

  protected getStorageKey(): string {
    return 'eyesnroad-acceptedLogin';
  }

  public getAcceptedLogin(): UserToken {
    const userToken = super.getObjectValue();
    if (userToken) {
      if (!SecurityTokenStorage.loginExpired(userToken)) {
        return userToken;
      } else {
        this.OnSessionExpired.next(userToken);
      }
    }
    return null;
  }
}
