import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../services/security/authentication.service';
import {LoaderService} from '../../../core/services/loader.service';
import { I18nService } from 'src/app/core/services/i18n.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() backgroundBlue = true;

  chatSwitch = false;
  chatTitle = '';

constructor(private authenticationService: AuthenticationService,
            private loaderService: LoaderService,
            private i18nService: I18nService) { }

ngOnInit() {
    this.chatTitle = this.i18nService.getText('chatOnline');
  }
logout() {
    this.loaderService.showLoader();
    this.authenticationService.logout();
  }

 chatSwitcher() {
  this.chatSwitch = !this.chatSwitch;
  if (this.chatSwitch) {
    this.chatTitle = this.i18nService.getText('chatOnlineClose');
    (window as any).HubSpotConversations.widget.open();
  } else {
    (window as any).HubSpotConversations.widget.close();
    this.chatTitle = this.i18nService.getText('chatOnline');
  }
}
}
