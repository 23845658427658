<div class="slider-item" (click)="click(image.project.id)" *ngIf="image.project">

  <div class="wrapper">

    <div class="opacity-div" [ngClass] = "{'failed-model': image.project.project_status_id === 2 }"></div>
    <img *ngIf="image && image.src" [src]="image.src" (error)="setDefaultImage($event)"/>


    <div class="model-category-translation" *ngIf="image.project.paymentPending">
      <div class="model-category">
        <img src="assets/images/gallery/km-icon.png"/>
        {{'excessKm' | i18n}}
        <span>{{'excessKmsub' | i18n}}</span>
      </div>
    </div>
    <div class="model">
        <p class="model-name">{{image.project.name | split:20 }}</p>

      <div class="line"></div>
        <div class="gallery-item-user-info-container">
          <div class="gallery-item-user-info-subcontainer">
            <img [src]="avatarSrc" class="user-photo" (error)="setDefaultAvatar($event)" />
            <div class="gallery-item-user-info-text">
              <p class="gallery-item-user-info-name">{{image.project.userName}} {{image.project.userSurname}}</p>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
