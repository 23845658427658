<div class="gallery-container" *ngIf="projectsToShow.length > 0">
  <div class="search-container" *ngIf="showSearch">
    <div class="search-header">
      <div class="search-input-container">
        <p class="d-none d-md-block">{{'searcher' | i18n}}</p>
        <div class="search-input">
          <img _ngcontent-swd-c259="" src="/assets/images/viewproject/searcher-ico2.svg">
          <input type="text" placeholder="{{'name' | i18n}}" (input)="filterChange($event.target.value)" value="{{searchValue}}">
        </div>
      </div>
      <div class="close-button-container" *ngIf="searchValue.length > 0">
        <p>{{'searcherExit' | i18n}}:</p>
        <a (click)="closeSearch()"><img src="/assets/images/gallery/close-search-icon.png"></a>
      </div>
    </div>
    <p class="search-text" *ngIf="searchProjects.length > 0">{{'searchResultText' | i18n}} “ {{searchValue}} ”</p>
    <div class="gallery-images-container" *ngIf="searchProjects && searchProjects.length > 0">
      <div class="gallery-images">
        <app-populars-item *ngFor="let project of searchProjects"
                           [project]="project"
                           (itemClick)="onSliderClick($event)">
        </app-populars-item>
      </div>
    </div>
    <div class="not-found-results" *ngIf="searchProjects.length == 0 && searchValue.length > 0">
      <img src="/assets/images/gallery/search-ico.png">
      <span class="vertical-line"></span>
      <p>{{'noResultsFound' | i18n}} “{{searchValue}}”</p>
    </div>

  </div>
  <div class="gallery-images-container">
    <div class="gallery-images" *ngIf="projectsToShow && projectsToShow.length > 0">
      <app-populars-item *ngFor="let project of projectsToShow"
                         [project]="project"
                         (itemClick)="onSliderClick($event)">
      </app-populars-item>
    </div>
    <app-google-adsense *ngIf="showGoogleAdsense"
      width = "160"
      height = "600">
    </app-google-adsense>
  </div>
</div>

