<div class="slider-item" (click)="click(project.id)" *ngIf="project">
  <div class="wrapper">
    <div class="opacity-div"></div>
    <img [src]="project.url" />
    <p class="model-name">{{project.name | split:10 }}</p>
    <div class="line"></div>
    <div class="gallery-item-user-info-container d-none d-md-flex">
      <div class="gallery-item-user-info-subcontainer">
        <img [src]="avatarSrc" class="user-photo" (error)="setDefaultAvatar($event)" />
        <div class="gallery-item-user-info-text">
          <p class="gallery-item-user-info-name">{{project.userName}} {{project.userSurname}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
