<div id="selectLanguage" [ngClass]="{'navbar': !footer}">
  <button mat-button [matMenuTriggerFor]="menu" style="background: 0 0;">
    <span class="selector-text">{{'language_name' | i18n}}</span>
    <mat-icon style="color: white;margin-left: 10px;">arrow_drop_down</mat-icon>
  </button>
  <!-- TODO David: Esto se hace estatico porque con el ngFor no se esta propagando el clik -->
  <mat-menu  #menu="matMenu">
    <button
      mat-menu-item
      *ngIf="currentLanguage.code != 'es'"
      (click)="setCurrentLanguage('es')">
      Español
    </button>
    <button
      mat-menu-item
      *ngIf="currentLanguage.code != 'en'"
      (click)="setCurrentLanguage('en')">
      English
    </button>
  </mat-menu>
</div>
