import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoaderService} from '../../core/services/loader.service';
import {Project} from 'src/app/models/projects/project';
import {ProjectThumbnail} from 'src/app/models/project-thumbnail';
import {UserRemoteService} from '../../core/services/remote/user/user.remote.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  [x: string]: any;

  constructor(private router: Router,
              private loaderService: LoaderService,
              private userRemoteService: UserRemoteService,
              private responsiveService: ResponsiveService) {
  }

  userProjects: Project[] = [];
  sliderImages: ProjectThumbnail[];
  isMobile = false;

  ngOnInit() {
    this.loaderService.showLoader();
    this.getUserProjects();
    this.isMobile = this.responsiveService.isMobile();
  }

  private getUserProjects() {
    this.userRemoteService.getProjects(10).subscribe(projects => {
      this.userProjects = projects;
      if (this.userProjects.length > 10) {
        this.userProjects = this.userProjects.slice(0, 10);
      }
      this.loadSliderImages();
      this.loaderService.hideLoader();
    }, error => this.loaderService.hideLoader());
  }

  private loadSliderImages() {
    this.sliderImages = [];
    this.userProjects.forEach(project => {
      this.sliderImages.push({
        name: project.name,
        src: project.url,
        project
      });
    });
  }

  gotToNewProject() {
    this.loaderService.showLoader();
    this.router.navigateByUrl('new-project');
  }
}
