<div class = "home-container">
  <div class = "home-buttons-container">
    <div id="home-new3d-button" (click)="gotToNewProject()">
      <div class="home-new3d-button-content">
        <img class="new3d-icon" src="/assets/images/home/pc-button-new-project-icon.svg">
        <p class="home-button-title">{{'generation' | i18n}}</p>
        <p class="d-none d-md-block home-button-text">{{'newProjectText' | i18n}}</p>
        <img class="d-none d-lg-block arrow" src="/assets/images/home/down-arrow-icon.png">
      </div>
    </div>
    <div id="home-right-container" *ngIf="!isMobile">
      <div id="home-gallery-button" routerLink="/gallery">
        <img class="new3d-icon d-none d-lg-block" src="/assets/images/home/pc-button-gallery-icon.svg">
        <p class="home-button-title">{{'proyectGallery' | i18n}}</p>
        <p class="d-none d-md-block gallery-button-text">{{'galleryText' | i18n}}</p>
        <img class="d-none d-lg-block arrow" src="/assets/images/home/down-arrow-icon.png">
      </div>
      <div id="home-right-last-container">
        <div class="home-current-models-button" routerLink="/projects-processing">
          <div class="project-management-logo"><img src="/assets/images/home/current-project.svg"></div>
          <p class="projects-in-process">{{'projectsInProcess' | i18n}}</p>
          <img class="d-none d-lg-block right-arrow" src="/assets/images/home/right-arrow-icon.svg">
        </div>
        <div class = "project-management-button-container">
          <div id="project-management-button" routerLink="/project-management">
            <div class="project-management-logo"><img class="project-management-icon" src="/assets/images/home/project-management-logo.png"></div>
            <p class="d-none d-md-block professional-button-title">{{'project_management' | i18n}}</p>
            <img class="d-none d-lg-block right-arrow" src="/assets/images/home/right-arrow-icon.svg">
          </div>
        </div>
      </div>
    </div>
    <div id="home-right-container" *ngIf="isMobile">
      <div class="home-current-models-button" routerLink="/projects-processing">
        <div class="project-management-logo"><img src="/assets/images/home/current-project-mobile.svg"></div>
      </div>
      <div id="home-right-last-container">
        <div id="home-gallery-button" routerLink="/gallery">
          <img class="new3d-icon d-none d-lg-block" src="/assets/images/home/pc-button-gallery-icon.svg">
          <p class="home-button-title">{{'proyectGallery' | i18n}}</p>
          <p class="d-none d-md-block gallery-button-text">{{'galleryText' | i18n}}</p>
          <img class="d-none d-lg-block arrow" src="/assets/images/home/down-arrow-icon.png">
        </div>
        <div class = "project-management-button-container">
          <div id="project-management-button" routerLink="/project-management">
            <p class="d-none d-md-block professional-button-title">{{'project_management' | i18n}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="home-gallery-container">
    <div class="home-gallery-subtitle-container">
      <p class="home-gallery-title">{{'myProjects' | i18n}}</p>
      <a class="home-gallery-subtitle-show-all" routerLink="/gallery">
        {{'showAll' | i18n}}
        <img class="d-lg-flex" src="/assets/images/home/button-show-all-icon-1920x960.svg">
      </a>
    </div>
    <div class = "home-gallery" *ngIf="sliderImages && sliderImages.length > 0">
      <app-gallery-slider
        [images]="sliderImages">
      </app-gallery-slider>
    </div>
    <div class = "no-projects-container" *ngIf="sliderImages && sliderImages.length === 0">
      <img src="/assets/images/home/no-projects.svg" alt="no projects">
      <div class="vertical-line"></div>
      <div class="no-projects-text-container">
        <p class="main-text">{{'noProjectsMainText' | i18n}}</p>
        <p class="secondary-text">{{'noProjectsSecondaryText' | i18n}}</p>
      </div>
    </div>
  </div>
</div>
