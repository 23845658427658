import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { _ } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { PaymentRemoteService } from 'src/app/core/services/remote/payment/payment-remote.service';
import { RedsysRemoteService } from 'src/app/core/services/remote/payment/redsys-remote.service';
import { ProjectRemoteService } from 'src/app/core/services/remote/project/project-remote.service';
import { AuthenticationService } from 'src/app/services/security/authentication.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { Payment } from 'src/app/shared/models/model/payment';
import { Rate } from 'src/app/shared/models/model/rate';
import { RedsysCreateParams } from 'src/app/shared/models/redsys/redsys-create-params';
import { RedsysResponse } from 'src/app/shared/models/redsys/redsys-response';
import { RedsysSubscriptionResponse } from 'src/app/shared/models/redsys/redsys-subscription-response';
import { User } from 'src/app/shared/models/user/user';
import { CreditsRemoteService } from '../../../../core/services/remote/payment/credits-remote.service';
import { RateRemoteService } from '../../../../core/services/remote/rate-remote.service';

@Component({
  selector: 'app-km-excess-dialog',
  templateUrl: './km-excess-dialog.component.html',
  styleUrls: ['./km-excess-dialog.component.scss']
})
export class KmExcessDialogComponent {
  notShowAgain = false;
  formatOption = 0;
  userRateId: number = undefined;
  nextRate: Rate = null;
  kmRate: Rate;
  projectId: number;
  user: User;
  exceededKms: number;

  formData: RedsysSubscriptionResponse;
  @ViewChild('form') form: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<KmExcessDialogComponent>,
    private storageService: StorageService,
    private creditsRemoteService: CreditsRemoteService,
    private rateRemoteService: RateRemoteService,
    private projectRemoteService: ProjectRemoteService,
    private redsysRemoteService: RedsysRemoteService,
    private authenticationService: AuthenticationService,
    private paymentRemoteService: PaymentRemoteService,
    private router: Router) {
    this.projectId = this.data;
    this.create();
  }

  /*onNoClick(): void {
    this.dialogRef.close(-1);
  }*/

  onAcceptClick(): void {
    if (this.notShowAgain) {
      this.storageService.set('roadDialog', this.formatOption.toString());
    }
    this.dialogRef.close(this.formatOption);
  }

  selectType(selection) {
    this.formatOption = selection;
  }

  notShowMessage(event) {
    this.notShowAgain = event.target.checked;
  }

  create(): void {
    this.creditsRemoteService.getUserCredits().subscribe(credit => {
      if (credit != null) {
        this.userRateId = credit.rate_id;
        this.rateRemoteService.get(credit.rate_id).subscribe(userRate => {
          if (userRate != null) {
            this.rateRemoteService.search().subscribe(rates => {
              rates.sort((a, b) => this.compareTwoRates(a, b)); // Se ordenan las suscripciones de menos a más KMs ofrecidos
              rates.forEach(rate => {
                if (this.nextRate === null && rate.kms > userRate.kms) {
                  this.nextRate = rate; // Establecemos la siguiente suscripción a la que tiene el usuario, y ya no volverá a entrar aquí
                }
                if (rate.name === 'EXCESO KM') { // Tarifa que especifica el caso de Kilómetros excedidos
                  this.kmRate = rate;
                  this.authenticationService.get().subscribe(user => {
                    this.user = user;
                    this.projectRemoteService.getCostPerExceededKm(this.projectId).subscribe(kms => { // Obtengo el número de km excedidos
                      this.exceededKms = kms;
                      this.prepareRedsysParams();
                    });
                  });
                }
              })
            })
          }
        })
      }

    });
  }

  compareTwoRates(a: Rate, b: Rate): number {
    return (a.kms - b.kms);
  }

  discardVideos() {
    this.projectRemoteService.discardExceededKmVideos(this.projectId).subscribe(() =>{
      this.dialogRef.close(this.formatOption);
      this.router.navigateByUrl('/home'); // Cuando el usuario opta por descartar los vídeos, cierro Pop-Up y al Home
    });
  }

  goToPayRedsys() {
    if (!this.formData) { return; }
    const payment: Payment = {
      user_id: this.user.id,
      rate_id: this.kmRate.id,
      amount: parseFloat((this.exceededKms * this.kmRate.price).toFixed(2)),
      payment_source_id: 1,
      order_number: this.formData.orderNumber,
      project_id: this.projectId
    };
    this.paymentRemoteService.add(payment).subscribe(() => {
      this.form.nativeElement.submit();
    });
  }

  private prepareRedsysParams() {
    const redsysParams: RedsysCreateParams = {
      rateId: this.kmRate.id,
      userId: this.user.id,
      price: parseFloat((this.exceededKms * this.kmRate.price).toFixed(2))
    };
    this.redsysRemoteService.createMerchantParameters(redsysParams).subscribe((data: RedsysResponse) => {
      this.formData = data;
    });
  }

}
