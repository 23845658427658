import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

  navbarBackgroundBlue = true;
  showHeaderBackground = true;
  showHeader = true;
  showFooter = true;
  lowHeader = false;

  constructor(private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url: string = event.url;

        this.showHeader = true;
        this.showFooter = true;
        if (url.includes('home') || url.includes('profile')) {
          this.navbarBackgroundBlue = true;
          this.showHeaderBackground = false;
        } else {
          this.navbarBackgroundBlue = false;
          this.showHeaderBackground = true;
        }

        if (url.includes('gallery') || url.includes('projects') || url === '/new-project') {
          this.lowHeader = true;
        } else {
          this.lowHeader = false;
        }

        if (url === '/projects-processing') {
          this.lowHeader = false;
        }

        if (url.includes('report-pdf')) {
          this.showHeader = false;
          this.showFooter = false;
        }
        if (url.includes('video-report')) {
          this.showHeader = false;
          this.showFooter = false;
        }
        if (url.includes('processing-report')) {
          this.showHeader = false;
          this.showFooter = false;
        }
        if (url.includes('download-report')) {
          this.showHeader = false;
          this.showFooter = false;
        }
      }
    });
  }
}
