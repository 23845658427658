<div class="row navbar-container">
  <div class="eyescloud-navbar" [ngClass]="{
     'navbar-background-blue': backgroundBlue,
     'navbar-background-white': !backgroundBlue
     }">

    <div class="logo-container">
      <img routerLink="/home" class="eyesnroad-navbar-img" src="/assets/images/navbar/blue/eyesnroad-logo.png"
        *ngIf="backgroundBlue">
      <img routerLink="/home" class="eyesnroad-navbar-img" src="/assets/images/navbar/white/eyesnroad-logo.png"
        *ngIf="!backgroundBlue">

      <img routerLink="/home" class="eyesnroad-logo-img" src="/assets/images/eyesnroad-logo-text-white2.png"
        *ngIf="backgroundBlue">
      <img routerLink="/home" class="eyesnroad-logo-img display-pc" src="/assets/images/eyesnroad-logo-text-blue.png"
        *ngIf="!backgroundBlue">
    </div>

    <div class="eyescloud-navbar-right-side">
      <a routerLink="/mobile-profile-menu" class="navbar-menu-link d-sm-block d-md-none">
        <img src="/assets/images/navbar/blue/menu-icon.png" *ngIf="backgroundBlue">
        <img src="/assets/images/navbar/white/menu-icon.png" *ngIf="!backgroundBlue">
      </a>
      <div class="d-none d-md-flex eyescloud-navbar-menu">
        <a routerLink="/new-project">
          <p>{{'newProject' | i18n}}</p>
        </a>
        <a routerLink="/gallery">
          <p>{{'gallery' | i18n}}</p>
        </a>
        <a routerLink="/profile/user-rates">
          <p>{{'account' | i18n}}</p>
        </a>
        <img class="line-separator" *ngIf="backgroundBlue" src="/assets/images/vertical-separator-white.png">
        <img class="line-separator" *ngIf="!backgroundBlue" src="/assets/images/vertical-separator-blue.png">
        <app-language-selector [footer]=false class="language-selector"></app-language-selector>
        <img class="line-separator" *ngIf="backgroundBlue" src="/assets/images/vertical-separator-white.png">
        <img class="line-separator" *ngIf="!backgroundBlue" src="/assets/images/vertical-separator-blue.png">
        <img class="logout-icon" src="/assets/images/logout-icon.svg" (click)="logout()">
      </div>
      <a (click)="chatSwitcher()" class="chat-online-button d-none d-md-flex">
        <p>{{chatTitle}}</p>
      </a>
    </div>
  </div>
</div>