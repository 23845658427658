import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BaseProfile} from '../../models/security/base-profile';
import {SecurityTokenStorage} from './security-token-storage';
import {LoginRemoteService} from './login-remote.service';
import {AcceptedLogin} from '../../models/security/accepted-login';
import {Observable, Subject} from 'rxjs';
import {Credential} from '../../models/security/credential';
import {RestClientService} from '../../core/services/api-access/rest-client.service';
import {SocialCredential} from '../../models/security/socialcredential';
import {I18nService} from '../../core/services/i18n.service';
import {ForgotPasswordRemoteService} from './forgot-password-remote.service';
import {LoaderService} from '../../core/services/loader.service';
import {ConfigurationRemoteService} from '../common/configuration-remote.service';
import {StorageService} from 'src/app/services/storage/storage.service';
import {UserRemoteService} from '../../core/services/remote/user/user.remote.service';
import {User} from '../../shared/models/user/user';
import {UserToken} from '../../models/security/user-token';
import {EmailVerifiedRemoteService} from './email-verified-remote.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private OnLogin = new Subject<AcceptedLogin<BaseProfile>>();
  private OnLogout = new Subject();
  private authUser;

  constructor(private loginRemoteService: LoginRemoteService,
              private securityTokenStorage: SecurityTokenStorage<UserToken>,
              private router: Router,
              private restClientService: RestClientService,
              private i18nService: I18nService,
              private forgotPasswordRemoteService: ForgotPasswordRemoteService,
              private loaderService: LoaderService,
              private emailVerifiedRemoteService: EmailVerifiedRemoteService,
              private configurationService: ConfigurationRemoteService,
              private userService: UserRemoteService,
              private storageService: StorageService
  ) {
    this.setDefaultLanguage();
    this.securityTokenStorage.onSessionExpired().subscribe(() => this.redirectToLogin());
  }

  static getAuthenticationUrl(): string {
    try {
      if (window.self !== window.top) {
        return '/login-bimserver';
      } else {
        return '/login';
      }
    } catch (e) {
      return '/login';
    }
  }

  get(redirectToLogin = true): Observable<User> {
    const subject = new Subject<any>();
    this.restClientService.get('auth-user').subscribe(user => {
      this.authUser = user;
      subject.next(user);
    }, () => {
      this.authUser = null;
      if (redirectToLogin) {
        this.redirectToLogin();
      } else {
        subject.error('Not authenticated user');
      }
    });
    return subject;
  }

  login<T extends BaseProfile>(email: string, password: string): Observable<AcceptedLogin<T>> {
    const subject = new Subject<AcceptedLogin<T>>();
    this.loginRemoteService.login<T>(new Credential(email, password))
      .subscribe((acceptedLogin) => {
        if (acceptedLogin.token) {
          this.authUser = acceptedLogin;
          this.configure(acceptedLogin);
          subject.next(acceptedLogin);
          this.OnLogin.next(acceptedLogin);
        } else {
          this.authUser = null;
          subject.error(acceptedLogin.error);
        }
      }, (error) => {
        this.authUser = null;
        subject.error(error.error);
      });
    return subject;
  }

  logout() {
    this.authUser = null;
    const tokenObj = this.securityTokenStorage.getAcceptedLogin();
    if (tokenObj !== null) {
      this.loginRemoteService.logout().subscribe(() => {
          this.loaderService.hideLoader();
          this.redirectToLogin();
        },
      );
    } else {
      this.redirectToLogin();
    }
  }

  deleteSecurityTokenInfo() {
    this.securityTokenStorage.deleteFromStorage();
    this.redirectToLogin();
  }

  onLogin(): Observable<any> {
    return this.OnLogin;
  }

  onLogout(): Observable<any> {
    return this.OnLogout;
  }

  socialLogin<T extends BaseProfile>(email: string, authToken: string): Observable<AcceptedLogin<T>> {
    const subject = new Subject<AcceptedLogin<T>>();
    this.loginRemoteService.socialLogin<T>(new SocialCredential(email, authToken))
      .subscribe((acceptedLogin) => {
        if (acceptedLogin.token) {
          this.configure(acceptedLogin);
          subject.next(acceptedLogin);
          this.OnLogin.next(acceptedLogin);
        } else {
          subject.error(acceptedLogin.error);
        }
      }, (error) => {
        subject.error(error.error);
      });
    return subject;
  }

  forgotPassword(email) {
    const subject = new Subject<any>();
    const emailParameter = {
      email: email.email,
      language_code: this.i18nService.getCurrentLanguage().code
    };

    this.forgotPasswordRemoteService.forgotPassword(emailParameter)
      .subscribe((response) => {
        subject.next(response);
      }, (error) => {
        subject.error(error);
      });
    return subject;
  }

  resetPassword(parameters) {
    const subject = new Subject<any>();
    this.forgotPasswordRemoteService.resetPassword(parameters)
      .subscribe((acceptedLogin) => {
        if (acceptedLogin.token) {
          this.configure(acceptedLogin);
          subject.next(acceptedLogin);
          this.OnLogin.next(acceptedLogin);
        } else {
          subject.error(acceptedLogin.error);
        }
      }, (error) => {
        subject.error(error);
      });
    return subject;
  }

  verifiedEmail<T extends BaseProfile>(token): Observable<AcceptedLogin<T>> {
    const subject = new Subject<any>();
    this.emailVerifiedRemoteService.verify(token)
      .subscribe((acceptedLogin) => {
        if (acceptedLogin.token) {
          this.configure(acceptedLogin);
          subject.next(acceptedLogin);
          this.OnLogin.next(acceptedLogin);
        } else {
          subject.error(acceptedLogin.error);
        }
      }, (error) => {
        subject.error(error.error);
      });

    return subject;
  }

  loginWithToken<T extends BaseProfile>(token): Observable<AcceptedLogin<T>> {
    const subject = new Subject<any>();
    this.loginRemoteService.loginWithToken(token)
      .subscribe((acceptedLogin) => {
        if (acceptedLogin.token) {
          this.configure(acceptedLogin);
          subject.next(acceptedLogin);
          this.OnLogin.next(acceptedLogin);
        } else {
          subject.error(acceptedLogin.error);
        }
      }, (error) => {
        subject.error(error.error);
      });

    return subject;
  }

  private setDefaultLanguage(): void {
    const userInfo = this.securityTokenStorage.getObjectValue();
    const defaultLanguage = userInfo
      ? userInfo.locale
      : I18nService.getSupportedLanguages()[1].code;

    if (this.storageService.get('language')) {
      this.i18nService.setCurrentLanguage(this.storageService.get('language'));
    } else if (userInfo) {
      this.i18nService.setCurrentLanguage(userInfo.locale);
    } else {
      const browserLang = navigator.language;
      const browserFormatted = browserLang.substr(0, 2);
      if (browserFormatted === 'en') {
        this.i18nService.setCurrentLanguage('en');
      } else if (browserFormatted === 'es') {
        this.i18nService.setCurrentLanguage('es');
      } else {
        this.i18nService.setCurrentLanguage(defaultLanguage);
      }
    }
  }

  private redirectToLogin(): void {
    this.securityTokenStorage.deleteFromStorage();
    this.loaderService.hideLoader();
    this.router.navigateByUrl(AuthenticationService.getAuthenticationUrl()).then(() => {
      this.OnLogout.next();
    });
  }

  private configure<T extends BaseProfile>(acceptedLogin: AcceptedLogin<T>): void {
    const tokenObj = {
      token: acceptedLogin.token,
      tokenExpirationDate: acceptedLogin.tokenExpirationDate,
      locale: acceptedLogin.locale
    };
    this.securityTokenStorage.saveObject(tokenObj);
    this.setDefaultLanguage();
  }
}
