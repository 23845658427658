import {Injectable} from '@angular/core';
import {RestClientService} from '../../api-access/rest-client.service';
import {Observable} from 'rxjs';
import {Credit} from '../../../../models/payment/credit';

@Injectable({
  providedIn: 'root'
})
export class CreditsRemoteService {

  private URL = 'credits';

  constructor(private restClientService: RestClientService) { }

  getUserCredits(): Observable<Credit> {
    return this.restClientService.get(`${this.URL}/user-credits`);
  }
}
