import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../api-access/rest-client.service';
import {Rate} from '../../../shared/models/model/rate';

@Injectable({
    providedIn: 'root'
})
export class RateRemoteService {

    private URL = '/rates';

    constructor(private restClientService: RestClientService) { }

    public search(): Observable<Rate[]> {
        return this.restClientService.get(`${this.URL}`);
    }

    public get(id: number): Observable<Rate> {
      return this.restClientService.get(`${this.URL}/all`);
    }
}
