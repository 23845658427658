import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AcceptedLogin} from '../../models/security/accepted-login';
import {Credential} from '../../models/security/credential';
import {RestClientService} from '../../core/services/api-access/rest-client.service';
import {BaseProfile} from '../../models/security/base-profile';
import {SocialAuthService} from 'angularx-social-login';
import {SocialCredential} from '../../models/security/socialcredential';

@Injectable({ providedIn: 'root' })
export class LoginRemoteService {
  constructor(private restClientService: RestClientService, private authService: SocialAuthService) { }

  public login<T extends BaseProfile>(credential: Credential): Observable<AcceptedLogin<T>> {
    return this.restClientService.post('/login', credential);
  }

  public socialLogin<T extends BaseProfile>(credential: SocialCredential): Observable<AcceptedLogin<T>> {
    return this.restClientService.post('/sociallogin', credential);
  }

  public logout(): Observable<any> {
    this.socialSignOut();
    return this.restClientService.get('/logout', null);
  }

  private socialSignOut() {
    this.authService.signOut();
  }

  public loginWithToken<T extends BaseProfile>(token: string): Observable<AcceptedLogin<T>> {
    return this.restClientService.get(`/user-by-token/${token}`);
  }
}
