import { Injectable } from '@angular/core';
import { RestClientService } from 'src/app/core/services/api-access/rest-client.service';
import { Observable } from 'rxjs';
import { Project } from 'src/app/models/projects/project';
import { Road } from '../../../../models/road';
import { SharedProject } from '../../../../models/sharedProject';

@Injectable({
  providedIn: 'root'
})
export class ProjectRemoteService {

  private URL = '/projects';
  private sharedURL = 'shared-projects';

  constructor(private restClientService: RestClientService) { }

  public getRoads(projectId: number): Observable<Road[]> {
    return this.restClientService.get(`${this.URL}/${projectId}/roads`);
  }

  create(project: Partial<Project>): Observable<Project> {
    return this.restClientService.post(this.URL, project);
  }

  get(id: number): Observable<Project> {
    return this.restClientService.get(`${this.URL}/${id}`);
  }

  getReportsInfo(id: number): Observable<any> {
    return this.restClientService.get(`${this.URL}/${id}/reports-info`);
  }

  getVideoPdf(id: number): Observable<any> {
    return this.restClientService.get(`${this.URL}/${id}/video-pdf`);
  }

  getProcessPdf(id: number): Observable<any> {
    return this.restClientService.get(`${this.URL}/${id}/process-pdf`);
  }

  getProcessIota(idProject: number, idInspection: number): Observable<any> {
    return this.restClientService.get(`${this.URL}/${idProject}/${idInspection}/get-process-iota`);
  }

  getDownloadPdf(id: number): Observable<any> {
    return this.restClientService.get(`${this.URL}/${id}/download-pdf`);
  }

  createSharedProject(data): Observable<any> {
    return this.restClientService.post(`${this.URL}/${data.id}/${this.sharedURL}`, data);
  }

  public getProcessingProjects(): Observable<any> {
    return this.restClientService.get(`${this.URL}/by/processings`, null);
  }

  public getLastProjects(): Observable<Project[]> {
    return this.restClientService.get(`${this.URL}/by/last`, null);
  }

  public getDepartmentProjects(): Observable<Project[]> {
    return this.restClientService.get(`${this.URL}/by/departments`, null);
  }

  public getSharedProjects(): Observable<SharedProject[]> {
    return this.restClientService.get(`${this.sharedURL}`, null);
  }

  public updateSharedProject(sharedProject: SharedProject): Observable<SharedProject> {
    return this.restClientService.put(`${this.sharedURL}/${sharedProject.id}`, sharedProject);
  }

  public deleteSharedProject(sharedProject: SharedProject): Observable<SharedProject> {
    return this.restClientService.put(`${this.sharedURL}/${sharedProject.id}/delete-shared-project`, sharedProject);
  }

  public getCostPerExceededKm(id: number): Observable<number>{
    return this.restClientService.get(`${this.URL}/${id}/exceeded-kms`);
  }

  public discardExceededKmVideos(id: number) {
    return this.restClientService.delete(`${this.URL}/${id}/exceeded-kms`);
  }

}
