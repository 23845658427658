import {Component, Input, OnInit} from '@angular/core';
import {Language} from '../../../models/language';
import {I18nService} from '../../../core/services/i18n.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  currentLanguage: Language;
  @Input() footer: boolean;

  constructor(private i18nService: I18nService,
              private router: Router) { }

  ngOnInit() {
    this.currentLanguage = this.i18nService.getCurrentLanguage();
  }

  public getOthersThanCurrentLanguage(): Language[] {
    return I18nService.getSupportedLanguages()
      .filter((x) => x.code !== this.i18nService.getCurrentLanguage().code);
  }

  setCurrentLanguage(code: string) {
    this.i18nService.setCurrentLanguage(code);
    this.currentLanguage = this.i18nService.getCurrentLanguage();
    this.reloadCurrentRoute();
  }

  private reloadCurrentRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const currentUrl = this.router.url;

    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      });
  }
}
