import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Project} from '../../../../models/projects/project';

@Component({
  selector: 'app-populars-item',
  templateUrl: './populars-item.component.html',
  styleUrls: ['./populars-item.component.scss']
})
export class PopularsItemComponent {

  avatarSrc = '';

  @Input() project: Project;
  @Output() itemClick = new EventEmitter<any>();

  click(code: number) {
    this.itemClick.emit(code);
  }

  setDefaultImage(event) {
    event.target.src = '/assets/images/blue-background.png';
  }

  setDefaultAvatar(event) {
    event.target.src = '/assets/images/gallery-item-default-avatar.png';
  }
}
