import * as moment_ from 'moment';
import {Injectable} from '@angular/core';
const moment = moment_;

@Injectable({
    providedIn: 'root',
})
export class DateService {
    static currentDate(): Date {
        return moment().toDate();
    }

    static dateIsPresentInRange(date: Date, startDate: Date, endDate: Date): boolean {
        return DateService.dateIsSameOrAfter(date, startDate) && DateService.dateIsSameOrBefore(date, endDate);
    }

    static getIsoStringDatePart(date: Date): string {
        return date.toISOString().slice(0, 10);
    }

    static getIsoString(date: Date): string {
        return date.toISOString();
    }

    static getDateFromIsoString(dateIsoString: string | Date): Date {
        return moment(dateIsoString, 'YYYY-MM-DD').toDate();
    }

    static cloneDate(date: Date): Date {
        return moment(date).toDate();
    }

    static dateIsBefore(date1: Date|string, date2: Date|string): boolean {
        return moment(DateService
          .getIsoStringDatePart(DateService.getDate(date1)))
          .isBefore(DateService.getIsoStringDatePart(DateService.getDate(date2)));
    }

    static datetimeIsBefore(date1: Date|string, date2: Date|string): boolean {
        return moment(date1).isBefore(date2);
    }

    static dateIsSameOrBefore(date1: Date|string, date2: Date|string): boolean {
        return moment(DateService
          .getIsoStringDatePart(DateService.getDate(date1)))
          .isSameOrBefore(DateService.getIsoStringDatePart(DateService.getDate(date2)));
    }

    static dateIsAfter(date1: Date|string, date2: Date|string): boolean {
        return moment(DateService
          .getIsoStringDatePart(DateService.getDate(date1)))
          .isAfter(DateService.getIsoStringDatePart(DateService.getDate(date2)));
    }

    static dateIsSameOrAfter(date1: Date|string, date2: Date|string): boolean {
        return moment(DateService
          .getIsoStringDatePart(DateService.getDate(date1)))
          .isSameOrAfter(DateService.getIsoStringDatePart(DateService.getDate(date2)));
    }

    static dateIsSame(date1: Date|string, date2: Date|string): boolean {
        return moment(DateService
          .getIsoStringDatePart(DateService.getDate(date1)))
          .isSame(DateService.getIsoStringDatePart(DateService.getDate(date2)));
    }

    static addDays(date: Date, days: number): Date {
        return moment(date).add(days, 'days').toDate();
    }

    static addMilliseconds(date: Date, milliseconds: number): Date {
        return moment(date).add(milliseconds, 'milliseconds').toDate();
    }

    static subtractDays(date: Date, days: number): Date {
        return moment(date).subtract(days, 'days').toDate();
    }

    static addMonths(date: Date, months: number): Date {
        return moment(date).add(months, 'month').toDate();
    }

    static formatDate(date: Date|string, format: string = 'DD-MM-YYYY', locale: string = 'en'): string {
        return moment(date).locale(locale).format(format);
    }

    static getTimeAgoStringFromDate(date: Date, locale: string): string {
        return moment(date).locale(locale).fromNow();
    }

    static getFullDateStringFromDate(date: Date, locale: string): string {
        return moment(date).toDate().toLocaleString(locale);
    }

    static nightsBetweenDates(startDate: Date, endDate: Date): number {
        return moment(endDate).diff(moment(startDate), 'days');
    }

    static getFirstDateOfMonthIsoString(): Date {
        return moment().startOf('month').toDate();
    }

    static getEndDateOfMonthIsoString(): Date {
        return moment().endOf('month').toDate();
    }

    static getDate(date: Date| string): Date {
        return typeof date === 'string' ? new Date(date) : date;
    }

    formatDateTime(date: Date| string, format: string = 'YYYY-MM-DD HH:mm:ss'): string {
        return DateService.formatDate(typeof date === 'string' ? new Date(date) : date, format);
    }
}
