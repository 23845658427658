import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {Project} from '../../../models/projects/project';
import {debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-gallery-grid',
  templateUrl: './gallery-grid.component.html',
  styleUrls: ['./gallery-grid.component.scss']
})
export class GalleryGridComponent implements OnInit, OnChanges {

  constructor(private router: Router) { }

  searchValue = '';
  searchProjects: Project[] = [];
  projectsToShow: Project[] = [];
  debouncer: Subject<string> = new Subject<string>();

  @Input() projects: Project[] = [];
  @Input() showSearch = false;
  @Input() showGoogleAdsense = false;
  @Input() gridItemPercent: number;

  @Output() filterChanged = new EventEmitter<string>();

  ngOnInit() {
    this.debouncer
      .pipe(debounceTime(300))
      .subscribe(value => {
        this.searchValue = value.toLowerCase();
        this.searchProjects = [];
        this.searchProjects = Object.assign([], this.projects);
        this.searchProjects = this.searchProjects.filter(project => project.name.toLowerCase().includes(this.searchValue));
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('projects') && !changes.projects.isFirstChange()) {
      this.projectsToShow = [];
      if (this.projects.length > 32) {
        this.projectsToShow = this.projects.slice(0, 32);
      } else {
        this.projectsToShow = Object.assign([], this.projects);
      }
    }
  }

  onSliderClick(code: string) {
    this.router.navigate(['/projects', code]);
  }

  filterChange(value: string) {
    this.debouncer.next(value);
  }

  closeSearch() {
    this.searchValue = '';
    this.searchProjects = [];
  }

  trackBySearcher(index: number, item: Project): number {
    return item.id;
  }
}
