import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProjectThumbnail} from 'src/app/models/project-thumbnail';

@Component({
  selector: 'app-gallery-item',
  templateUrl: './gallery-item.component.html',
  styleUrls: ['./gallery-item.component.scss']
})
export class GalleryItemComponent {

  avatarSrc = '';

  @Input() image: ProjectThumbnail;
  @Output() itemClick = new EventEmitter<any>();

  constructor() { }

  click(code: number) {
    this.itemClick.emit(this.image.project.id);
  }

  setDefaultImage(event) {
    event.target.src = '/assets/images/blue-background.png';
  }

  setDefaultAvatar(event) {
    event.target.src = '/assets/images/gallery-item-default-avatar.png';
  }
}

