export const COMMONS_I18N_ENTRIES = {
  gallery: {
    en: 'Gallery',
    es: 'Galería',
  },
  proyectGallery: {
    en: 'Projects Gallery',
    es: 'Galería de proyectos',
  },
  loading: {
    en: 'Loading',
    es: 'Cargando',
  },
  company: {
    en: 'company',
    es: 'empresa',
  },
  professional: {
    en: 'professional',
    es: 'profesional'
  },
  project_management: {
    en: 'Project management',
    es: 'Gestión de proyectos'
  },
  projectsInProcess: {
    en: 'Projects in process',
    es: 'Proyectos en proceso'
  },
  user: {
    en: 'User',
    es: 'Usuario',
  },
  viewMore: {
    en: 'view more',
    es: 'ver más',
  },
  nameRequired: {
    en: 'Name is required',
    es: 'El nombre es obligatorio',
  },
  name: {
    en: 'Name',
    es: 'Nombre'
  },
  emailRequired: {
    en: 'Email is required',
    es: 'El correo electrónico es obligatorio',
  },
  surname: {
    en: 'Surname *',
    es: 'Apellidos *'
  },
  save: {
    en: 'save',
    es: 'guardar'
  },
  cancel: {
    en: 'Cancel',
    es: 'Cancelar'
  },
  continue: {
    en: 'Continue',
    es: 'Continuar'
  },
  models: {
    en: 'models',
    es: 'modelos'
  },
  badEmail: {
    en: 'Bad email',
    es: 'Correo erróneo',
  },
  minPasswordError: {
    en: 'Password must be more than 4 characters',
    es: 'La contraseña tiene que tener más de 4 caracteres',
  },
  notEqualPasswords: {
    en: 'Passwords are not equals',
    es: 'La contraseñas no coinciden',
  },
  followUsIn: {
    en: 'Follow us in',
    es: 'Síguenos en',
  },
  termsOfUse: {
    en: 'Terms of use',
    es: 'Términos de uso'
  },
  privacy: {
    en: 'Privacy',
    es: 'Privacidad',
  },
  privacyPolicy: {
    en: 'Privacy Policy',
    es: 'Política de Privacidad',
  },
  cookiePolicies: {
    en: 'Cookie policies',
    es: 'Políticas de cookies'
  },
  projectIplusD: {
    es: 'Proyectos de I+D',
    en: 'R & D Projects'
  },
  downloadApp: {
    en: 'Download App',
    es: 'Descarga la aplicación'
  },
delete: {
  en: 'Delete',
    es: 'Eliminar'
},
footerText: {
  en: ' eyesNroad.com is an eCapture3D product. Each of the projects generated in eyesNroad.com are property of the users',
    es: ' eyesNroad.com es un producto de eCapture3D. Cada uno de los proyectos generados en eyesNroad.com son propiedad de sus usuarios'
},
searcher: {
  en: 'Searcher',
    es: 'Buscador'
},
searcherExit: {
  en: 'Exit the searcher',
    es: 'Salir del buscador'
},
noResultsFound: {
  en: 'No results found for',
    es: 'No se han encontrado resultados para'
},
then: {
  en: 'Then',
    es: 'A continuación'
},
mostPopulars3dModels: {
  en: 'Most populars 3D models',
    es: 'Modelos 3d Más Populares'
},
searchResultText: {
  en: 'Search result of',
    es: 'Resultado de la búsqueda de'
},
newProject: {
  en: 'New project',
    es: 'Nuevo proyecto'
},
popular: {
  en: 'Popular',
    es: 'Popular'
},
myPortfolio: {
  en: 'My portfolio',
    es: 'Mi portafolio'
},
myLast: {
  en: 'My last',
    es: 'Mis últimos'
},
myFavorites: {
  en: 'My favorites',
    es: 'Mis favoritos'
},
myProjects: {
  en: 'My projects',
    es: 'Mis proyectos'
},
myShared: {
  en: 'My Shared',
    es: 'Mis Compartidos'
},
excessKm: {
  en: 'Excess Km',
    es: 'Exceso Km'
},
excessKmsub: {
  en: 'in your subscription',
    es: 'en tu suscripción'
},
whatdouwtd: {
  en: 'What do you want to do?',
    es: '¿Qué quieres hacer?'
},
additionalCost: {
  en: 'Cost per additional KM',
    es: 'Coste por km adicional'
},
upgradeSub: {
  en: 'Upgrade subscription',
    es: 'Actualizar suscripción'
},
equalKm: {
  en: 'Compensate Km with Suscrption next month',
    es: 'Compensar Km con suscripción mes próximo'
},
equalKm2: {
  en: 'Compensate extra Km with Km next month',
    es: 'Compensar Km excedidos con Km mes próximo'
},
deleteKm: {
  en: 'Delete extra KM',
    es: 'Eliminar KM excedidos'
},
deleteKm2: {
  en: 'Delete extra Km of the roads study',
    es: 'Eliminar km restantes del Estudio de carretera'
},
taxesIncluded: {
  en: 'taxes included',
    es: 'impuestos incluidos'
},
ksub: {
  en: 'SUBSCRIPTION',
    es: 'SUSCRIPCIÓN'
},
calculating: {
  en: 'Calculating',
    es: 'Calculando'
}
};
