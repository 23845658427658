import {Injectable} from '@angular/core';
import {RestClientService} from 'src/app/core/services/api-access/rest-client.service';
import {Observable} from 'rxjs';
import {Inspection} from '../../../../models/inspection';

@Injectable({
    providedIn: 'root'
})
export class Ec2RemoteService {

  private URL = '/ec2';

  constructor(private restClientService: RestClientService) { }

  public startProcessingServer(inspection: Inspection): Observable<any> {
    return this.restClientService.post(`${this.URL}/start-processing-server/${inspection.id}`, null);
  }
}
