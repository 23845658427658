import {Injectable} from '@angular/core';
import {RestClientService} from 'src/app/core/services/api-access/rest-client.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProjectFilesRemoteService {

  private URL = '/project-files';

  constructor(private restClientService: RestClientService) { }

  public getUrlProjectPreviewImage(projectId: number): Observable<string> {
    return this.restClientService.get(`${this.URL}/${projectId}/preview`, null);
  }
  public getFramesOfVideosSelected(videoIds: number[]): Observable<Blob> {
    return this.restClientService.getBlob(`${this.URL}/get-frames`, {ids: videoIds});
  }
}
